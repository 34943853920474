import React, { Fragment, useEffect, useState } from 'react';
import { useContext } from 'react';
import AuthContext from '../../store/auth-context';
import { Row, Col, Table, Button, Form, Modal } from 'react-bootstrap';
import { toast } from "react-toastify";
import LoadingSpinner from '../../shared/LoadingSpinner';
import './programs.scss';

const Programs = (props) => {
    const authCtx = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isNew, setIsNew] = useState(false);
    const [programs, setPrograms] = useState([]);
    const newEmptyProgram = {
        programName: null,
        contractAddress: null,
        symbol: null,
        chainType: 'ethereum'
    }
    const [np, setNp] = useState(newEmptyProgram);

    const getPrograms = () => {
        setLoading(true);
        authCtx.getData('/get-programs').then(response => {
            setPrograms(response);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            toast.error(error.response.data.statusMessage || error);
        });
    }
    useEffect(() => {
        getPrograms();
        setShowModal(false);
    }, []);

    const onAddNew = () => {
        setIsNew(true);
        setNp(newEmptyProgram);
        setSubmitting(false);
        setShowModal(true);

    }
    const inputChangeHandler = (e) => {
        const { id, value } = e.target;
        setNp(prev => { return { ...prev, [id]: value ? value : null } });
    }
    const onSubmitHander = (e) => {
        e.preventDefault();
        if (!window.confirm('Are you sure?')) return false;
        setSubmitting(true);

        const program = {
            programName: np.programName,
            contractAddress: np.contractAddress,
            symbol: np.symbol,
            chainType: np.chainType
        }
        if (!isNew) program.programId = np.programId;

        authCtx
            .postData((isNew ? '/add-program' : '/update-program'), program)
            .then((res) => {
                getPrograms();
                toast.success('Program ' + (isNew ? 'Added' : 'Updated') + ' Successfully');
                setShowModal(false);
            })
            .catch(err => {
                setSubmitting(false);
                toast.error(err.message);
            });
    };
    const editProgramHandler = (prg) => {
        setIsNew(false);
        setNp(prg);
        setSubmitting(false);
        setShowModal(true);
    }

    console.log('isNew', isNew);
    console.log('np', np);

    return <Fragment>
        <Row>
            <Col sm={6}>
                <h1 className='mb-0'>Programs</h1>
                <p className="small">All the programs to BTCpx can be found here</p>
            </Col>
            <Col sm={6}>
                <Button size="sm" title='Add New Program' onClick={onAddNew} className="pull-right">
                    <i className='fa fa-plus'></i>
                </Button>
            </Col>
        </Row>
        {loading && <LoadingSpinner asOverlay />}
        {!loading && <div className="mt-3 mb-3 button-container">
            <Row>
                <Col>
                    <Table bordered hover>
                        <thead>
                            <tr variant='light'>
                                <th className='text-right' style={{ width: '50px' }}>#</th>
                                <th>Program Id</th>
                                <th>Program Name</th>
                                <th>Contract Address</th>
                                <th>Symbol</th>
                                <th>Status</th>
                                <th>Created At</th>
                                <th>Chain Type</th>
                                <th style={{ width: '60px' }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {programs.map((prg, i) => <tr key={prg.programId} >
                                <td className='text-right'>{i + 1}</td>
                                <td>{prg.programId}</td>
                                <td>{prg.programName}</td>
                                <td className='contract-address'>{prg.contractAddress}</td>
                                <td>{prg.symbol}</td>
                                <td>{prg.status ? 'True' : 'False'}</td>
                                <td>{prg.createdAt}</td>
                                <td>{prg.chainType}</td>
                                <td>
                                    <Button size="sm" variant='success' onClick={editProgramHandler.bind(this, prg)} title='Edit' >
                                        <i className='fa fa-edit'></i>
                                    </Button>
                                </td>
                            </tr>)}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </div>
        }

        <Modal
            size="md"
            show={showModal}
            backdrop="static"
        >
            <Form onSubmit={onSubmitHander}>
                <Modal.Header>
                    <Modal.Title className={`fw ${ isNew ? 'text-primary' : 'text-success' }`}> {isNew ? 'Add New' : 'Update'} Program
                        {!submitting && <Button className='close-btn small pull-right' variant='light' onClick={() => setShowModal(false)}>
                            <i className='fa fa-remove' />
                        </Button>}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='pd-1'>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label htmlFor='programName'>Program Name</Form.Label>
                                <Form.Control id='programName' value={np.programName} type='text' onChange={inputChangeHandler} placeholder='Enter Program Name' autoComplete='pass2' required />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col>
                            <Form.Group>
                                <Form.Label htmlFor='contractAddress'>Contract Address</Form.Label>
                                <Form.Control id='contractAddress' value={np.contractAddress} type='text' onChange={inputChangeHandler} placeholder='Enter Contract Address' autoComplete='pass2' required />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col sm={6}>
                            <Form.Group>
                                <Form.Label htmlFor='symbol'>Symbol</Form.Label>
                                <Form.Control id='symbol' value={np.symbol} type='text' onChange={inputChangeHandler} placeholder='Enter symbol' autoComplete='pass2' required />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group>
                                <Form.Label htmlFor='symbol'>Chain Type</Form.Label>
                                <Form.Control as="select" id='chainType' value={np.chainType} onChange={inputChangeHandler}>
                                    <option>ethereum</option>
                                    <option>polygon</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {submitting && <LoadingSpinner />}
                    <Button type='submit' variant={`${ isNew ? 'primary' : 'success' }`} disabled={submitting} >{isNew ? 'Save' : 'Update'}</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    </Fragment >
};

export default Programs;