import React, {Fragment, useEffect, useState} from 'react';
import {useContext} from 'react';
import AuthContext from '../../store/auth-context';
import {Row, Col, Button, Form, Modal, Table} from 'react-bootstrap';
import './deposit-addresses.scss';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {toast} from "react-toastify";
import LoadingSpinner from '../../shared/LoadingSpinner';

const DepositAddresses = (props) => {
    const authCtx = useContext(AuthContext);
    const ethExplorerLink = process.env.REACT_APP_ethExplorerLink;
    const polygonExplorerLink = process.env.REACT_APP_polygonExplorerLink;
    const bitcoinExplorerLink = process.env.REACT_APP_bitcoinExplorerLink;
    const [loading, setLoading] = useState(false);
    const [addressDetailLoader, setaddressDetailLoader] = useState(false);
    const [txs, setTxs] = useState([]);
    const [addressDetail, setAddressDetail] = useState({});
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [addressId, setAddressId] = useState();

    const [config, setConfig] = useState({
        page: 1,
        sizePerPage: 10,
        totalSize: 0,
        hideSizePerPage: true,
    });
    const [params, setParams] = useState({
        fromDate: null,
        toDate: null,
        search: null,
        bitcoinLegacyAddress: null,
        depositAddress: null,
        network: null,
        type: null
    });
    const statusList = ['completed', 'created', 'not-picked', 'initiated', 'in-progress', 'failed', 'error'];
    const today = (new Date()).toISOString().split("T")[0];
    const handleTableChange = (type, {page, sizePerPage}) => {
        if (type === "pagination") getTxs(page);
    };
    const getTxs = (pageNo = 1) => {
        setLoading(true);
        const keys = Object.keys(params).filter(k => params[k] != undefined).map(key => key);
        const query = keys.map(key => {
            let newKey;
            if (key === 'fromDate' || key === 'toDate') {
                newKey = key + '=' + new Date(params[key]).toISOString()
            } else {
                newKey = key + '=' + params[key]
            }
            return newKey;
        }).join('&');
        authCtx
            .getData(`/address/get-addresses/${pageNo}/10?${query}`)
            .then((res) => {
                // setTxs(res.transactions);
                setTxs(res.addresses);
                setConfig({...config, page: pageNo, totalSize: res.total});
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                toast.error(err.message)
            });
    };
    const bitcoinLegacyAddressFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                {row.bitcoinLegacyAddress &&
                    <a href={`${bitcoinExplorerLink}/address/${row.bitcoinLegacyAddress}`} target="_blank">
                        <span class='clip-text link-blue'>
                            {row.bitcoinLegacyAddress}
                        </span>
                    </a>
                }
                {!row.bitcoinLegacyAddress && '-'}
            </div>
        );
    };
    const depositAddressFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                {row.depositAddress &&
                    <a href={`${row.network === 'polygon' ? polygonExplorerLink : ethExplorerLink}/address/${row.depositAddress}`}
                       target="_blank">
                        <span class='clip-text link-blue'>
                            {row.depositAddress}
                        </span>
                    </a>
                }
                {!row.depositAddress && '-'}
            </div>
        );
    };
    const searchFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                <Button size="sm" onClick={getAddressDetails.bind(this, row.addressId)}>
                    <i class='fa fa-search'></i>
                </Button>
            </div>
        )
    }


    const columns = [
        {
            dataField: "sl",
            text: "#",
            align: "right",
            headerStyle: () => {
                return {width: "25px", textAlign: "right"};
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return rowIndex + 1;
            },
            editable: false,
        },
        {
            dataField: "addressId",
            type: "text",
            text: "Address Id",
            style: {whiteSpace: 'normal', wordWrap: 'break-word'}
        },
        {
            dataField: "bitcoinLegacyAddress",
            text: "Bitcoin Legacy Address",
            style: {whiteSpace: 'normal', wordWrap: 'break-word'},
            formatter: bitcoinLegacyAddressFormatter,
        },
        {
            dataField: "depositAddress",
            text: "Deposit Address",
            style: {whiteSpace: 'normal', wordWrap: 'break-word'},
            formatter: depositAddressFormatter
        },
        {
            dataField: "network",
            text: "Network",
            style: {whiteSpace: 'normal', wordWrap: 'break-word'}
        },
        {
            dataField: "createdAt",
            text: "Date",
            style: {whiteSpace: 'normal', wordWrap: 'break-word'}
        },
        {
            dataField: "extraData.cfray",
            text: "C. Fray",
            style: {whiteSpace: 'normal', wordWrap: 'break-word'}
        }
        ,
        {
            dataField: "extraData.forwardedfor",
            text: "Forwarded For",
            headerStyle: () => {
                return {width: "110px"};
            },
            style: {whiteSpace: 'normal', wordWrap: 'break-word'}
        },
        {
            dataField: "extraData.ip",
            text: "IP",
            style: {whiteSpace: 'normal', wordWrap: 'break-word'}
        },
        {
            dataField: "extraData.ipcountry",
            text: "Country",
            style: {whiteSpace: 'normal', wordWrap: 'break-word'}
        },
        {
            isDummyField: true,
            dataField: "edit",
            text: "",
            headerAttrs: {width: 40},
            formatter: searchFormatter
        }

    ];

    const inputChangeHandler = (e) => {
        const {id, value} = e.target;
        setParams(prev => {
            return {...prev, [id]: value ? value : null}
        });
        setTxs([]);
        setConfig({...config, totalSize: 0});
    }
    const onSubmitHander = (e) => {
        e.preventDefault();
        getTxs();
    };

    const getAddressDetails = (addressId) => {
        setShowDetailModal(true);
        setaddressDetailLoader(true);
        setAddressId(addressId);
        setAddressDetail({});
        authCtx
            .getData(`/address-details?address_id=${addressId}`)
            .then((res) => {
                setAddressDetail(res);
                setaddressDetailLoader(false);
            })
            .catch(err => {
                setaddressDetailLoader(false);
                toast.error(err.message)
            });
    };
    useEffect(() => {
        getTxs();
    }, []);

    return <Fragment>
        <h1 className='mb-0'>Deposits Addresses</h1>
        <p className="small">List Deposits Addresses to BTCpx can be found here</p>

        {loading && <LoadingSpinner asOverlay/>}
        <Form onSubmit={onSubmitHander}>
            <Row>
                <Col sm={2} className="mt-1">
                    <Form.Label htmlFor='fromDate'> From Date </Form.Label>
                    <Form.Control type="date" id='fromDate' value={params.fromDate} onChange={inputChangeHandler}
                                  max={today}/>
                </Col>
                <Col sm={2} className="mt-1">
                    <Form.Label htmlFor='toDate'> To Date </Form.Label>
                    <Form.Control type="date" id='toDate' value={params.toDate} onChange={inputChangeHandler}
                                  max={today}/>
                </Col>
                <Col sm={2} className="mt-1">
                    <Form.Label htmlFor='search'> Search </Form.Label>
                    <Form.Control type="text" id='search' value={params.search} onChange={inputChangeHandler}
                                  placeholder="search"/>
                </Col>
                <Col sm={3} className="mt-1">
                    <Form.Label htmlFor='bitcoinLegacyAddress'> Bitcoin Legacy Address </Form.Label>
                    <Form.Control type="text" id='bitcoinLegacyAddress' value={params.bitcoinLegacyAddress}
                                  onChange={inputChangeHandler} placeholder="Bitcoin Legacy Address"
                                  autoComplete='pass2'/>
                </Col>
                <Col sm={2} className="mt-1">
                    <Form.Label htmlFor='depositAddress'> Deposit Address</Form.Label>
                    <Form.Control type="text" id='depositAddress' value={params.depositAddress}
                                  onChange={inputChangeHandler} placeholder="Deposit Address" autoComplete='pass2'/>
                </Col>
                <Col sm={2} className="mt-1">
                    <Form.Label htmlFor='network'> Network </Form.Label>
                    <Form.Control as="select" id='network' value={params.network} onChange={inputChangeHandler}>
                        <option value="">--</option>
                        <option>ethereum</option>
                        <option>polygon</option>
                    </Form.Control>
                </Col>
                <Col sm={2} className="mt-1">
                    <Form.Label htmlFor='type'> Type </Form.Label>
                    <Form.Control as="select" id='type' value={params.type} onChange={inputChangeHandler}>
                        <option value="">--</option>
                        <option>auto</option>
                        <option>manual</option>
                    </Form.Control>
                </Col>
                <Col sm={2} className="mt-1">
                    <Button disabled={loading} type='submit' size="sm" className="mt-4 search-btn">Search</Button>
                </Col>
            </Row>
        </Form>
        <Row className="mt-3">
            <Col className="mb-5">
                <BootstrapTable
                    remote={{pagination: true, filter: false, sort: false}}
                    bootstrap4
                    keyField="transactionId"
                    data={txs}
                    headerClasses="bg-light"
                    wrapperClasses="table-responsive table-sm"
                    columns={columns}
                    pagination={paginationFactory(config)}
                    onTableChange={handleTableChange}
                    hover
                    condensed
                    tabIndexCell
                />
            </Col>
        </Row>

        <Modal
            size="lg"
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={showDetailModal}
            onHide={() => setShowDetailModal(false)}
        >
            {addressDetailLoader && <LoadingSpinner asOverlay/>}
            <Modal.Header>
                <Modal.Title className="text-primary fw"> Transaction Details
                    <Button className='close-btn small pull-right' variant='light'
                            onClick={() => setShowDetailModal(false)}>
                        <i className='fa fa-remove'/>
                    </Button>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="no-padding">
                {!addressDetailLoader && <Table bordered hover size="sm">
                    <tbody>
                    <tr>
                        <th style={{width: '25%'}}>Address Id</th>
                        <td>{addressId}</td>
                    </tr>
                    </tbody>
                </Table>}
            </Modal.Body>
        </Modal>

    </Fragment>
};

export default DepositAddresses;