import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Button, Modal, Card, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/bit-icon.png';
import AuthContext from "../../store/auth-context";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import './login.scss';

const Login = (props) => {
  const navigate = useNavigate();
  const [submitting, setSubmiting] = useState(false);
  const [twofastatus, setTwofastatus] = useState(false);
  const [show2FABox, setShow2FABox] = useState(false);
  const [newSecretCode, setNewSecretCode] = useState();
  const [logging2FA, setLogging2FA] = useState(false);
  const authCtx = useContext(AuthContext);

  const [formState, setFormState] = useState({ email: null, password: null });
  const inputHandler = (e) => {
    const { id, value } = e.target;
    setFormState(prev => { return { ...prev, [id]: value } });
  }

  useEffect(() => {
    authCtx.logout();
  }, []);

  const loginSubmitHandler = (event) => {
    event.preventDefault();
    setSubmiting(true);
    authCtx
      .postData("/auth/login", formState)
      .then((response) => {
        const tokenInfo = getDecodedAccessToken(response.accessToken);
        saveToken(tokenInfo, response.accessToken, tokenInfo.sub);
        setTwofastatus(tokenInfo.sub === '2fa-access');
        authCtx.preLogin(response.accessToken);
        if (tokenInfo.sub === '2fa-access') {
          setShow2FABox(true);
          toast.warning('2FA Security Mode is ON.');
          setNewSecretCode('');
        } else {
          toast.success('Logged In Successfully!');
          setSubmiting(false);
          navigate("/");
        }
      })
      .catch((error) => {
        console.log('error', error);
        setSubmiting(false);
        toast.error(error.response.data.statusMessage || error);
      });
  };

  const login2faHandler = (e) => {
    e.preventDefault();
    setLogging2FA(true);
    authCtx
      .postData("/auth/login/two-fa", { code: newSecretCode })
      .then((response) => {
        const tokenInfo = getDecodedAccessToken(response.accessToken);
        setTwofastatus(tokenInfo.sub == '2fa-access');
        saveToken(tokenInfo, response.accessToken, '2fa-access');
        toast.success('Logged In Successfully!');
        setSubmiting(false);
        navigate("/");
      })
      .catch((error) => {
        setLogging2FA(false);
        console.log('error', error);
        toast.error(error.response.data.statusMessage || error);
      });

  }

  const saveToken = (tokenInfo, token, sub = 'website') => {
    const user = {
      token: token,
      email: formState.email,
      exp: tokenInfo.exp,
      iat: tokenInfo.iat,
      id: tokenInfo.id,
      nonce: tokenInfo.nonce,
      sub: tokenInfo.sub,
      name: formState.email.split("@")[0],
      desig: 'Admin',
      profileImg: 'assets/images/user-profile.png',
      newSecretCode: newSecretCode
    };
    localStorage.setItem('user', JSON.stringify(user));
    const authData = {
      name: formState.email.split("@")[0],
      email: formState.email,
      token: token
    };
    authCtx.login(token, { authData });
  };

  const getDecodedAccessToken = (token) => {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  };
  const on2FaLoginModalCloseHandler = () => {
    setShow2FABox(false);
    setSubmiting(false);
  }

  return (
    <Container fluid className="login-wrapper">
      <div className="login-box">
        <h1 className="text-center mb-5">
          <img src={logo} className="text-primary" alt="logo" />
          BTCpx Admin
        </h1>
        <Row>
          <Col md={6} sm={6} className="col-12 login-box-info">
            <h3 className="mb-4">Sign up</h3>
            <p className="mb-4">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch.</p>
            <p className="text-center"><a href="#" className="btn btn-light">Register here</a></p>
          </Col>
          <Col md={6} sm={6} className="col-12 login-box-form p-4">
            <h3 className="mb-2">Login</h3>
            <small className="text-muted bc-description">Sign in with your credentials</small>
            <form onSubmit={loginSubmitHandler} className="mt-2">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1"><i className="fa fa-envelope-o fa-fw"></i></span>
                </div>
                <input type="email" onChange={inputHandler} value={formState.email} className="form-control mt-0 input-underline" id="email" placeholder="Email" name="email" required />
              </div>

              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1" ><i className="fa fa-key fa-fw"></i></span>
                </div>
                <input type="password" onChange={inputHandler} value={formState.password} className="form-control mt-0 input-underline" placeholder="Password" id='password' name="password" required />
              </div>
              <div className="form-group">
                <Button
                  type="submit"
                  variant="primary"
                  className="btn-theme btn-block p-2 mb-1"
                  disabled={!formState.email || !formState.password || submitting}
                >
                  Login
                  {submitting && <i className="fa fa-refresh fa-spin ml-1" aria-hidden="true"></i>}
                </Button>
                <a href="#">
                  <small className="text-theme"><strong>Forgot password?</strong></small>
                </a>
              </div>
            </form>
          </Col>
        </Row>
      </div>

      <Modal
        size="md"
        dialogClassName="modal-90w"
        show={show2FABox}
        onHide={() => setSubmiting(false)}
        backdrop="static"
      >
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Body className="no-padding small">
          <Card>
            <Card.Header>
              <Button size="sm" variant="light" className="modal-close-btn" onClick={on2FaLoginModalCloseHandler}>
                <i className="fa fa-remove"></i>
              </Button>
              <h5 className='text-primary text-center'> 2FA Security is ON </h5>
              <p>
                Enter six-digit code from your mobile app ( for example, <span className='text-primary'> "Google authenticator" </span> or <span className='text-primary'>"Authy"</span>). The application will show a six-digit code, which must be specified in the next field to login through 2FA.
              </p>
            </Card.Header>
            <Card.Body className='twofa-card-body'>
              <Form id="2FAFormDisable" className="mt-2" onSubmit={login2faHandler}>
                <Row>
                  <Col sm={{ span: 6, offset: 1 }}>
                    <Form.Group>
                      <input type="text" id="newSecretCode" value={newSecretCode} name="newSecretCode" className="form-control text-center" placeholder="Enter 6 digit code" required pattern="^[0-9]*$" maxlength="6" minlength="6" autocomplete="doNotAutoComplete"
                        onChange={(e) => setNewSecretCode(e.target.value)} />
                    </Form.Group>
                  </Col>
                  <Col sm={4} className="text-center">
                    <Button disabled={logging2FA || !newSecretCode} type="submit" id="submitBtnDisable" variant="primary" size="sm">
                      Login
                      {logging2FA && <i className='fa fa-spinner fa-pulse' aria-hidden="true" ></i>}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>

          </Card>
        </Modal.Body>
      </Modal >

    </Container >
  )
};

export default Login;
