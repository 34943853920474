import React, {Fragment, useEffect, useState} from 'react';
import {useContext} from 'react';
import AuthContext from '../../store/auth-context';
import {Row, Col, Button, Form, Modal, Table} from 'react-bootstrap';
import './deposits.scss';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {toast} from "react-toastify";
import LoadingSpinner from '../../shared/LoadingSpinner';

const Deposits = (props) => {
    const authCtx = useContext(AuthContext);
    const ethExplorerLink = process.env.REACT_APP_ethExplorerLink;
    const polygonExplorerLink = process.env.REACT_APP_polygonExplorerLink;
    const bitcoinExplorerLink = process.env.REACT_APP_bitcoinExplorerLink;
    const [loading, setLoading] = useState(false);
    const [txDetailLoader, setTxDetailLoader] = useState(false);
    const [txs, setTxs] = useState([]);
    const [txDetail, setTxsDetail] = useState({});
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [transactionId, setTransactionId] = useState();
    const [settlementHash, setSettlementHash] = useState();
    const [network, setNetwork] = useState();

    const [config, setConfig] = useState({
        page: 1,
        sizePerPage: 10,
        totalSize: 0,
        hideSizePerPage: true,
    });
    const [params, setParams] = useState({
        transaction_type: 'deposit',
        fromDate: null,
        toDate: null,
        search: null,
        bitcoinHash: null,
        network: null,
        bitcoinAddress: null,
        ethAddress: null
    });
    const statusList = ['completed', 'created', 'not-picked', 'initiated', 'in-progress', 'failed', 'error'];
    const today = (new Date()).toISOString().split("T")[0];
    const handleTableChange = (type, {page, sizePerPage}) => {
        if (type === "pagination") getTxs(page);
    };
    const getTxs = (pageNo = 1) => {
        setLoading(true);
        const keys = Object.keys(params).filter(k => params[k] != undefined).map(key => key);
        const query = keys.map(key => {
            let newKey;
            if (key === 'fromDate' || key === 'toDate') {
                newKey = key + '=' + new Date(params[key]).toISOString()
            } else {
                newKey = key + '=' + params[key]
            }
            return newKey;
        }).join('&');
        authCtx
            .getData(`/admin/transactions/${pageNo}/10?${query}`)
            .then((res) => {
                setTxs(res.transactions);
                setConfig({...config, page: pageNo, totalSize: res.total});
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                toast.error(err.message)
            });
    };
    const bitcoinAddressFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                {row.bitcoinAddress &&
                    <a href={`${bitcoinExplorerLink}/address/${row.bitcoinAddress}`} target="_blank">
                        <span class='clip-text link-blue'>
                            {row.bitcoinAddress}
                        </span>
                    </a>
                }
                {!row.bitcoinAddress && '-'}
            </div>
        );
    };
    const bitcoinHashFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                {row.bitcoinHash &&
                    <a href={`${bitcoinExplorerLink}/tx/${row.bitcoinHash}`} target="_blank">
                        <span class='clip-text link-blue'>
                            {row.bitcoinHash}
                        </span>
                    </a>
                }
                {!row.bitcoinHash && '-'}
            </div>
        );
    };
    const settlementHashFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                {row.settlementHash &&
                    <a href={`${row.network === 'polygon' ? polygonExplorerLink : ethExplorerLink}/tx/${row.settlementHash}`}
                       target="_blank">
                        <span class='clip-text link-blue'>
                            {row.settlementHash}
                        </span>
                    </a>
                }
                {!row.settlementHash && '-'}
            </div>
        );
    };
    const searchFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                <Button size="sm" onClick={getTxsDetails.bind(this, row.transactionId, row.settlementHash, row.network)}>
                    <i class='fa fa-search'></i>
                </Button>
            </div>
        )
    };

    const columns = [
        {
            dataField: "sl",
            text: "#",
            align: "right",
            headerStyle: () => {
                return {width: "25px", textAlign: "right"};
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return rowIndex + 1;
            },
            editable: false,
        },
        {
            dataField: "transactionId",
            type: "text",
            text: "Tx Id",
            style: {whiteSpace: 'normal', wordWrap: 'break-word'}
        },
        {
            dataField: "ethAddress",
            text: "Eth Address",
            style: {whiteSpace: 'normal', wordWrap: 'break-word'}
        },
        {
            dataField: "bitcoinAddress",
            text: "Bitcoin Address",
            style: {whiteSpace: 'normal', wordWrap: 'break-word'},
            formatter: bitcoinAddressFormatter,
        },
        {
            dataField: "bitcoinHash",
            text: "Bitcoin Hash",
            style: {whiteSpace: 'normal', wordWrap: 'break-word'},
            formatter: bitcoinHashFormatter
        },
        {
            dataField: "settlementHash",
            text: "Settlement Hash",
            style: {whiteSpace: 'normal', wordWrap: 'break-word'},
            formatter: settlementHashFormatter
        },
        {
            dataField: "amount",
            text: "Amount",
            style: {whiteSpace: 'normal', wordWrap: 'break-word'},
            align: 'right',
            headerStyle: {textAlign: "right"},
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return Number(row.amount).toFixed(6);
            },
        },
        {
            dataField: "status",
            text: "Status",
            style: {whiteSpace: 'normal', wordWrap: 'break-word'}
        },
        {
            dataField: "network",
            text: "Network",
            style: {whiteSpace: 'normal', wordWrap: 'break-word'}
        },
        {
            dataField: "createdAt",
            text: "Date",
            style: {whiteSpace: 'normal', wordWrap: 'break-word'}
        },
        {
            isDummyField: true,
            dataField: "edit",
            text: "",
            headerAttrs: {width: 40},
            formatter: searchFormatter
        }
    ];

    const inputChangeHandler = (e) => {
        const {id, value} = e.target;
        setParams(prev => {
            return {...prev, [id]: value ? value : null}
        });
        setTxs([]);
        setConfig({...config, totalSize: 0});
    }
    const onSubmitHander = (e) => {
        e.preventDefault();
        getTxs();
    };

    const getTxsDetails = (transactionId, settlementHash, network) => {
        setShowDetailModal(true);
        setTxDetailLoader(true);
        setTransactionId(transactionId);
        setSettlementHash(settlementHash);
        setNetwork(network);
        setTxsDetail({});
        authCtx
            .getData(`/transaction-details?transactionId=${transactionId}`)
            .then((res) => {
                setTxsDetail(res);
                setTxDetailLoader(false);
            })
            .catch(err => {
                setTxDetailLoader(false);
                toast.error(err.message)
            });
    };
    useEffect(() => {
        getTxs();
    }, []);

    return <Fragment>
        <h1 className='mb-0'>Deposits</h1>
        <p className="small">All the deposits to BTCpx can be found here</p>

        {loading && <LoadingSpinner asOverlay/>}
        <Form onSubmit={onSubmitHander}>
            <Row>
                <Col sm={2} className="mt-1">
                    <Form.Label htmlFor='fromDate'> From Date </Form.Label>
                    <Form.Control type="date" id='fromDate' value={params.fromDate} onChange={inputChangeHandler}
                                  max={today}/>
                </Col>
                <Col sm={2} className="mt-1">
                    <Form.Label htmlFor='toDate'> To Date </Form.Label>
                    <Form.Control type="date" id='toDate' value={params.toDate} onChange={inputChangeHandler}
                                  max={today}/>
                </Col>
                <Col sm={2} className="mt-1">
                    <Form.Label htmlFor='search'> Search </Form.Label>
                    <Form.Control type="text" id='search' value={params.search} onChange={inputChangeHandler}
                                  placeholder="search"/>
                </Col>
                <Col sm={2} className="mt-1">
                    <Form.Label htmlFor='bitcoinHash'> Bitcoin Hash </Form.Label>
                    <Form.Control type="text" id='bitcoinHash' value={params.bitcoinHash} onChange={inputChangeHandler}
                                  placeholder="Bitcoin Hash" autoComplete='pass2'/>
                </Col>
                <Col sm={2} className="mt-1">
                    <Form.Label htmlFor='status'> Status </Form.Label>
                    <Form.Control as="select" id='status' value={params.status} onChange={inputChangeHandler}>
                        <option value="">--</option>
                        {statusList.map(opt => <option>{opt}</option>)}
                    </Form.Control>
                </Col>
                <Col sm={2} className="mt-1">
                    <Form.Label htmlFor='network'> Network </Form.Label>
                    <Form.Control as="select" id='network' value={params.network} onChange={inputChangeHandler}>
                        <option value="">--</option>
                        <option>ethereum</option>
                        <option>polygon</option>
                    </Form.Control>
                </Col>
                <Col sm={2} className="mt-1">
                    <Form.Label htmlFor='bitcoinAddress'> Bitcoin Address</Form.Label>
                    <Form.Control type="text" id='bitcoinAddress' value={params.bitcoinAddress}
                                  onChange={inputChangeHandler} placeholder="Bitcoin Address" autoComplete='pass2'/>
                </Col>
                <Col sm={2} className="mt-1">
                    <Form.Label htmlFor='ethAddress'> Eth Address </Form.Label>
                    <Form.Control type="text" id='ethAddress' value={params.ethAddress} onChange={inputChangeHandler}
                                  placeholder="Eth Address" autoComplete='pass2'/>
                </Col>
                <Col sm={2} className="mt-1">
                    <Button disabled={loading} type='submit' size="sm" className="mt-4 search-btn">Search</Button>
                </Col>
            </Row>
        </Form>
        <Row className="mt-3">
            <Col className="mb-5">
                <BootstrapTable
                    remote={{pagination: true, filter: false, sort: false}}
                    bootstrap4
                    keyField="transactionId"
                    data={txs}
                    headerClasses="bg-light"
                    wrapperClasses="table-responsive table-sm"
                    columns={columns}
                    pagination={paginationFactory(config)}
                    onTableChange={handleTableChange}
                    hover
                    condensed
                    tabIndexCell
                />
            </Col>
        </Row>

        <Modal
            size="lg"
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            show={showDetailModal}
            onHide={() => setShowDetailModal(false)}
        >
            {txDetailLoader && <LoadingSpinner asOverlay/>}
            <Modal.Header>
                <Modal.Title className="text-primary fw"> Transaction Details
                    <Button className='close-btn small pull-right' variant='light'
                            onClick={() => setShowDetailModal(false)}>
                        <i className='fa fa-remove'/>
                    </Button>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="no-padding">
                {!txDetailLoader && <Table bordered hover size="sm">
                    <tbody>
                    <tr>
                        <th style={{width: '25%'}}>Transaction Id</th>
                        <td>{transactionId}</td>
                    </tr>
                    <tr>
                        <th>Network</th>
                        <td>{network}</td>
                    </tr>
                    <tr>
                        <th>Status</th>
                        <td>{txDetail.status}</td>
                    </tr>
                    <tr>
                        <th>Amount</th>
                        <td>{txDetail.amount}</td>
                    </tr>
                    <tr>
                        <th>Amount in USD</th>
                        <td>{txDetail.amountUSD}</td>
                    </tr>
                    <tr>
                        <th>Network Fee</th>
                        <td>{txDetail.networkFee}</td>
                    </tr>
                    <tr>
                        <th>Withdrawal Address</th>
                        <td>
                            {txDetail.destinationAddress &&
                                <a href={`${bitcoinExplorerLink}/address/${txDetail.destinationAddress}`}
                                   target="_blank">
                                <span className='link-blue'>
                                    {txDetail.destinationAddress}
                                </span>
                                </a>
                            }
                            {!txDetail.destinationAddress && '-'}
                        </td>
                    </tr>
                    <tr>
                        <th>Settlement Hash</th>
                        <td>
                            {settlementHash &&
                                <a href={`${network === 'polygon' ? polygonExplorerLink : ethExplorerLink}/tx/${settlementHash}`}
                                   target="_blank">
                                <span class='link-blue'>
                                    {settlementHash}
                                </span>
                                </a>
                            }
                            {!settlementHash && '-'}
                        </td>
                    </tr>
                    <tr>
                        <th>Bitcoin Transaction Hash</th>
                        <td>
                            {txDetail.txHash &&
                                <a href={`${bitcoinExplorerLink}/tx/${txDetail.txHash}`}
                                   target="_blank">
                                <span className='link-blue'>
                                    {txDetail.txHash}
                                </span>
                                </a>
                            }
                            {!txDetail.txHash && '-'}
                        </td>
                    </tr>
                    <tr>
                        <th>Note</th>
                        <td>{txDetail.note ? txDetail.note : '-'}</td>
                    </tr>
                    </tbody>
                </Table>}
            </Modal.Body>
        </Modal>
    </Fragment>
};

export default Deposits;