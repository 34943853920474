import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Deposits from "../pages/Deposits/deposits";
import DepositAddresses from "../pages/DepositAddresses/deposit-addresses";
import Withdrawals from "../pages/Withdrawals/withdrawals";
import UserProfile from '../pages/UserProfile/user-profile';
import LoadingSpinner from "../shared/LoadingSpinner";
import Balances from "../pages/Balances/balances";
import Programs from "../pages/Programs/programs";
import DashBoard from "../pages/Dashboard/dashboard";
const NotFound = () => {
    return <h1>Page Not Found!</h1>;
};
const AuthRoutes = () => {
    return <Suspense fallback={<div className="center"> <LoadingSpinner asOverlay /> </div>} >
        <Routes>
            <Route path="/" element={<DashBoard />} />
            <Route path="/deposits" element={<Deposits />} />
            <Route path="/withdrawals" element={<Withdrawals />} />
            <Route path="/deposit-addresses" element={<DepositAddresses />} />
            <Route path="/balances" element={<Balances />} />
            <Route path="/programs" element={<Programs />} />
            <Route path="/user-profile" element={<UserProfile />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    </Suspense>;
};

export default AuthRoutes;