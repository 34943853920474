import React, { Fragment, useEffect, useState } from 'react';
import { useContext } from 'react';
import AuthContext from '../../store/auth-context';
import { Row, Col, Table } from 'react-bootstrap';
import { toast } from "react-toastify";
import LoadingSpinner from '../../shared/LoadingSpinner';
import './balances.scss';

const Balances = (props) => {
    const authCtx = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [txs, setTxs] = useState({
        custodianBalance: null,
        custodianBalanceUSD: null,
        btcpxBalance: null,
        btcpxBalanceUSD: null
    });

    const getBalances = () => {
        setLoading(true);
        authCtx.getData(`/wallet/balance`).then(response => {
            setTxs(response);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            toast.error(error.response.data.statusMessage || error);
        });
    }
    useEffect(() => {
        getBalances();
    }, []);

    return <Fragment>
        <h1 className='mb-0'>Balances</h1>
        <p className="small">All the balances to BTCpx can be found here</p>

        {loading && <LoadingSpinner asOverlay />}
        {!loading && <div className="mt-3 mb-3 button-container">
            <Row>
                <Col sm={6}>
                    <Table className='table table-stripped table-bordered'>
                        <tbody>
                            <tr>
                                <th>
                                    CUSTODIAN BALANCE
                                </th>
                                <td>
                                    {txs.custodianBalance}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    CUSTODIAN BALANCE (USD)
                                </th>
                                <td>
                                    {txs.custodianBalanceUSD}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    BTCPX BALANCE
                                </th>
                                <td>
                                    {txs.btcpxBalance}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    BTCPX BALANCE (USD)
                                </th>
                                <td>
                                    {txs.btcpxBalanceUSD}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </div>
        }

    </Fragment >
};

export default Balances;