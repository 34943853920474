import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import AuthContext from '../../store/auth-context';
import './dashboard.scss';
import { toast } from "react-toastify";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


const DashBoard = (props) => {
    const authCtx = useContext(AuthContext);
    const today = (new Date()).toISOString().split("T")[0];
    const [params, setParams] = useState({ fromDate: null, toDate: null, graphType: 'hour' });
    const [txsLoader, setTxsLoader] = useState(false);
    const [stats, setStats] = useState({ mintingStats: [], redemptionStats: [] });
    const [labels, setLabels] = useState([]);
    const [data, setData] = useState({});
    const graphTypes = ['second', 'minute', 'hour', 'day', 'week', 'month', 'year'];

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Minting vs Redemption Line Chart',
            },
        },
    };

    const formatDate = (dt) => {
        return dt.split("T")[0];
    }

    useEffect(() => {
        getStats();
        getGraphicalData();
    }, [params]);

    const getStats = () => {
        setTxsLoader(true);
        setStats({ mintingStats: [], redemptionStats: [] });
        const query = [];
        if (params.fromDate) {
            const dtFrom = new Date(params.fromDate).toISOString();
            query.push(`fromDate=${ dtFrom }`);
        }
        if (params.toDate) {
            const dtTo = new Date(params.toDate).toISOString();
            query.push(`toDate=${ dtTo }`);
        }
        const queryString = query.length ? "?" + query.join("&") : "";


        authCtx.getData(`/cards${ queryString }`).then(response => {
            setStats(response);
            setTxsLoader(false);
        }).catch((error) => {
            setTxsLoader(false);
            toast.error(error.response.data.statusMessage || error);
        });
    }
    const getGraphicalData = () => {
        authCtx.getData(`/graph?type=${ params.graphType }`).then(response => {
            // setGraph(response);
            const labels = response.mintingStats.map((item) => formatDate(item.tt));
            setLabels(labels);
            setData({
                labels,
                datasets: [
                    {
                        label: 'Minting',
                        data: response.mintingStats.map((item) => item.totaltransactions),
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    },
                    {
                        label: 'Redemption',
                        data: response.redemptionStats.map((item) => item.totaltransactions),
                        borderColor: 'rgb(53, 162, 235)',
                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    }
                ],
            });
            setTxsLoader(false);
        }).catch((error) => {
            setTxsLoader(false);
            console.log(error);
            toast.error(error.response.data.statusMessage || error);
        });
    }

    const inputChangeHandler = (e) => {
        const { id, value } = e.target;
        setParams(prev => { return { ...prev, [id]: value } });
    }
    return <Fragment>
        <Row>
            <Col lg={3}>
                <h1 className='mb-3'>Dashboard</h1>
            </Col>
            <Col md={{ span: 2, offset: 4 }}>
                <Form.Group>
                    <Form.Label htmlFor="fromDate" >Date From</Form.Label>
                    <input type="date" id="fromDate" value={params.fromDate} className="form-control input-sm" onChange={inputChangeHandler} />
                </Form.Group>
            </Col>
            <Col md={2}>
                <Form.Group>
                    <Form.Label htmlFor="toDate" >To</Form.Label>
                    <input type="date" id="toDate" value={params.toDate} className="form-control input-sm" onChange={inputChangeHandler} />
                </Form.Group>
            </Col>
            <Col md={1}>
                <Form.Group>
                    <Form.Label htmlFor="graphType" >Types</Form.Label>
                    <Form.Select id="graphType" value={params.graphType} className="form-control input-sm" onChange={inputChangeHandler}>
                        {graphTypes.map(x => <option>{x}</option>)}

                    </Form.Select>
                </Form.Group>
            </Col>

        </Row>
        {txsLoader && <Row>
            <Col>
                <div className='text-center mb-3'>
                    <i className='fa fa-spinner fa-pulse fa-2x' aria-hidden="true"></i>
                </div>
            </Col>
        </Row>}
        {!txsLoader && <div className="mt-3 mb-3 button-container">
            <Row className="pl-0">
                <Col lg={3} md={3} sm={6} size="12" className="mb-3">
                    <div className="bg-theme border shadow rounded f-h">
                        <div className="p-2 mb-1 text-center">
                            <h5 className="mb-0 mt-2 text-light"><small><strong> TOTAL MINTING </strong></small></h5>
                            <h3> {Number(+stats.mintingStats.totaldeposits).toFixed(6)} BTC </h3>
                        </div>
                        <div className="text-center text-light">
                            Total {stats.mintingStats.count} Transactions
                        </div>
                    </div>
                </Col>
                <Col lg={3} md={3} sm={6} size="12" className="mb-3">
                    <div className="bg-success border shadow rounded f-h">
                        <div className="p-2 mb-1 text-center">
                            <h5 className="mb-0 mt-2 text-light"><small><strong> TOTAL REDEEMPTIONS </strong></small></h5>

                            <h3 className="text-white"> {Number(stats.redemptionStats.totalwithdrawals).toFixed(6)} BTC </h3>
                        </div>
                        <div className="text-center text-light">
                            Total {stats.redemptionStats.totalwithdrawalstxs} Transactions
                        </div>
                    </div>
                </Col>
                <Col lg={3} md={3} sm={6} size="12" className="mb-3">
                    <div className="bg-danger border shadow rounded f-h">
                        <div className="p-2 mb-1 text-center">
                            <h5 className="mb-0 mt-2 text-light"><small><strong> MINTING PROFIT </strong></small></h5>
                            <h3 className="text-white"> {Number(stats.mintingStats.profit).toFixed(6) || 0} BTC </h3>
                        </div>
                    </div>
                </Col>
                <Col lg={3} md={3} sm={6} size="12" className="mb-3">
                    <div className="bg-theme border shadow rounded f-h">
                        <div className="p-2 text-center">
                            <h5 className="mb-0 mt-2 text-light"><small><strong> REDEEMPTION PROFIT </strong></small></h5>
                            <h3 className="text-white"> {Number(stats.mintingStats.profit).toFixed(6) || 0} BTC </h3>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>}

        {!txsLoader && (data.labels && data.labels.length) > 0 && <Line options={options} data={data} />}


    </Fragment >
};

export default DashBoard;