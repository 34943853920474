import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
const AuthContext = React.createContext({
  token: "",
  isLoggedIn: false,
  login: (token) => { },
  preLogin: () => { },
  logout: () => { },
  getData: () => { },
  postData: () => { },
  user: {}
});

export const AuthContextProvider = (props) => {
  const BASE_URL = process.env.REACT_APP_API_ENDPOINT;
  const [token, setToken] = useState(localStorage.getItem("token"));
  const userIsLoggedIn = !!token;
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("userData")) || {});

  const preLoginHandler = (tkn) => {
    setToken(tkn);
    localStorage.setItem("token", tkn);
  };
  const loginHandler = (tkn, usrData) => {
    setToken(tkn);
    localStorage.setItem("token", tkn);

    setUserData(usrData);
    localStorage.setItem("userData", JSON.stringify(usrData));
  };
  const logoutHandler = () => {
    if (token) {
      const body = {};
      const headers = {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
        }
      };
      axios.post(BASE_URL + "/auth/logout", body, headers)
        .then((response) => {
          toast.info(response.data.statusMessage);
        })
        .catch((err) => toast.error(err.message));
    }

    setToken(null);
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
  };

  const getDataHandler = async (url) => {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      }
    };
    const response = await axios.get(BASE_URL + url, headers);
    return response.data;
  };
  const postDataHandler = async (url, params) => {
    const body = JSON.stringify(params);
    const headers = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      }
    };
    const response = await axios.post(BASE_URL + url, body, headers);
    return response.data;
  };


  const contextValue = {
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    preLogin: preLoginHandler,
    logout: logoutHandler,
    getData: getDataHandler,
    postData: postDataHandler,
    user: userData
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
