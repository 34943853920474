import React, { useState } from "react";
import { Link } from "react-router-dom";
import profileImg from '../../assets/images/user-profile.png';

const Sidebar = (props) => {
  const [activeMenu, setActiveMenu] = useState(window.location.pathname);
  const user = {};
  const menus = [
    { id: 1, name: 'Dashboard', url: '/' },
    { id: 2, name: 'Deposits', url: '/deposits' },
    { id: 3, name: 'Withdrawals', url: '/withdrawals' },
    { id: 4, name: 'Deposit Addresses', url: '/deposit-addresses' },
    { id: 7, name: 'Balances', url: '/balances' },
    { id: 8, name: 'Programs', url: '/programs' }
  ];
  const menuClickHandler = (e) => {
    setActiveMenu("/" + e.target.href.split("/").pop());
  }


  return <div className="inner-sidebar mr-3">
    <div className="avatar text-center">
      <img src={profileImg} alt="" className="profileImg rounded-circle" />
      <p id='userName' className='bold'>{user.name}</p>
      <span id='userDesig' className="text-primary small bold"> {user.desig} </span>
    </div>
    <div className="sidebar-menu-container">
      <ul className="sidebar-menu mt-4 mb-4">
        <ul className="children" id="dashboard">

          {menus.map((menu, index) => {
            return <li className="child">
              <Link key={menu.id} to={menu.url} className={"ml-4" + (activeMenu === menu.url ? " active" : "")} onClick={menuClickHandler}>
                <i className="fa fa-angle-right mr-1"></i> {menu.name}
              </Link>
            </li>
          })}

        </ul>
      </ul>
    </div>
  </div>
}

export default Sidebar;
