import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import AuthContext from "../store/auth-context";
import Footer from '../shared/Footer/Footer';
import AuthRoutes from './AuthRoutes';
import { Container } from 'react-bootstrap';
import Header from '../shared/Header/Header';
import Sidebar from '../shared/Sidebar/Sidebar';
import { Row, Col } from 'react-bootstrap';
import './layout.scss';

const Layout = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const [pageRendered, setPageRendered] = useState(false);

  useEffect(() => {
    if (!authCtx.isLoggedIn) {
      console.log('not logged in');
      navigate("/login");
    }
    setPageRendered(true);
  }, []);

  return (pageRendered && <Container fluid>
    <Header />
    <Row className="main-content">
      <Col sm={2} xs={6} className="sidebar pl-0">
        <Sidebar />
      </Col>
      <Col sm={10} xs={12} className="content pt-3 pl-0" style={{ height: '100vh' }}>
        <AuthRoutes />
        {/* <Footer /> */}
      </Col>
    </Row>
  </Container>);
}
export default Layout;