import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.scss';
import { Row, Col, DropdownButton, Dropdown } from 'react-bootstrap';
import logo from '../../assets/images/bit-icon.png';
import profileImg from '../../assets/images/user-profile.png';

const Header = (props) => {
    const [toggleClass, setToggleClass] = useState(false);

    const toggleMenuHandler = () => {
        setToggleClass(!toggleClass);
    };

    return <Row className="header shadow-sm">
        <Col sm={2} className="pl-0 text-center header-logo">
            <div className="bg-theme mr-3 pt-3 pb-2 mb-0">
                <h3 className="logo">
                    <Link to="/" className="text-secondary logo">
                        <img src={logo} className="logo text-primary mr-1" alt="" />
                        BTCpx Admin
                    </Link>
                </h3>
            </div>
        </Col>
        <Col sm={10} className="header-menu pt-2 pb-0">
            <Row>
                <Col sm={4} className="pl-0">
                    <span className="menu-icon" onClick="toggleMenu()" >
                        <span id="sidebar-toggle-btn" ngclassName="{ 'button--grey slide-in': !toggleClass }"></span>
                    </span>
                    {/* Toggle sidebar */}
                    {/* Notification icon */}
                    <div className="menu-icon">
                        <Link to="/" role="button" className="dropdown-toggle">
                            <i className="fa fa-bell"></i>
                            <span className="badge badge-danger">5</span>
                        </Link>
                    </div>
                    {/* Notication icon */}

                    {/* Inbox icon */}
                    <span className="menu-icon inbox">
                        <Link to="/" role="button" id="dropdownMenuLink3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fa fa-envelope"></i>
                            <span className="badge badge-danger">4</span>
                        </Link>
                    </span>
                    {/* Inbox icon */}
                    <span className="menu-icon">
                        <i className="fa fa-th-large"></i>
                    </span>
                </Col >
                <Col sm={4} className="pl-0">
                    <span className="menu-icon" onClick={toggleMenuHandler} >
                        <span id="sidebar-toggle-btn" className={!toggleClass ? 'button--grey slide-in' : ''}  ></span>
                    </span>
                </Col>
                <Col sm={4} className="text-right flex-header-menu justify-content-end">
                    <DropdownButton id="dropdown-basic-button" variant='basic' title={<img src={profileImg} alt="Adam" className="profileImg rounded-circle" width="40px" height="40px" />}>
                        <Dropdown.Item>
                            <Link className="dropdown-item" to="/user-profile"><i className="fa fa-user pr-2"></i> Profile</Link>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <Link className="dropdown-item" to="/login"><i className="fa fa-power-off pr-2"></i> Logout</Link>
                        </Dropdown.Item>
                    </DropdownButton>
                </Col>
            </Row >
        </Col >
    </Row >
}

export default Header;