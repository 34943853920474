import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Button, Form, Card } from 'react-bootstrap';
import AuthContext from '../../store/auth-context';
import './user-profile.scss';
import { toast } from "react-toastify";


const UserProfile = (props) => {
    const [loading, setLoading] = useState(false);
    const [twoFaStatus, setTwoFaStatus] = useState(false);
    const [generatingQR, setGeneratingQR] = useState(false);
    const [generatedQR, setGeneratedQR] = useState(false);
    const [show2faDisablingForm, setShow2faDisablingForm] = useState(false);
    const [show2faEnablingForm, setShow2faEnablingForm] = useState(false);
    const [newSecretCode, setNewSecretCode] = useState();
    const [enabling2FA, setEnabling2FA] = useState(false);
    const [disabling2FA, setDisabling2FA] = useState(false);
    const authCtx = useContext(AuthContext);
    const user = authCtx.user.authData || {};
    const [qrCode, setQrCode] = useState("");

    useEffect(() => {
        get2faStatus();
    }, []);
    const get2faStatus = () => {
        setLoading(true);
        authCtx.getData('/two-fa/status').then(response => {
            setTwoFaStatus(response.status === 'enabled');
            setLoading(false);
        }).catch((error) => {
            toast.error(error.response.data.statusMessage || error);
        });;
    }

    const generate2FASecret = () => {
        setNewSecretCode();
        setEnabling2FA(false);
        authCtx
            .postData("/two-fa/generate")
            .then((response) => {
                setQrCode(`http://api.qrserver.com/v1/create-qr-code/?data=${ response.url }!&size=200x200&bgcolor=ffffff`);
                setShow2faEnablingForm(true);
                setGeneratedQR(true);
            })
            .catch((error) => {
                setGeneratedQR(false);
                console.log('error', error);
                toast.error(error.response.data.statusMessage || error);
            });
    }
    const newSecretCodeHandler = (e) => {
        setNewSecretCode(e.target.value);
    }
    const onEnabling2faHander = (e) => {
        e.preventDefault();
        setEnabling2FA(true);
        authCtx
            .postData('/two-fa/enable', { code: newSecretCode })
            .then(data => {
                setEnabling2FA(false);
                setShow2faDisablingForm(false);
                setShow2faEnablingForm(false);
                setTwoFaStatus(true);
                toast.success(data.statusMessage);
            }).catch((error) => {
                setEnabling2FA(false);
                console.log('error', error);
                toast.error(error.response.data.statusMessage || error);
            });
    }
    const disable2faHandler = (e) => {
        e.preventDefault();
        setDisabling2FA(true);

        authCtx.postData('/two-fa/disable', { code: newSecretCode })
            .then(data => {
                setDisabling2FA(false);
                setShow2faDisablingForm(false);
                setTwoFaStatus(false);
                toast.success(data.statusMessage);
            }).catch((error) => {
                setDisabling2FA(false);
                toast.error(error.response.data.statusMessage || error);
            });
    }
    const onClick2FaDisable = () => {
        setShow2faDisablingForm(true);
        setNewSecretCode();
    }

    return <>
        <h1 className='mb-0'>User Profile</h1>
        <p className='small'> User details are given here </p>
        <Row className="mt-2 border-bottom mb-4">
            <Col sm={3}>
                <Form.Group>
                    <Form.Label>Full Name</Form.Label>
                    <p className="bold">{user.name}</p>
                </Form.Group>
            </Col>
            <Col sm={3}>
                <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <p className="bold">{user.email}</p>
                </Form.Group>
            </Col>
            <Col sm={3}>
                <Form.Group>
                    <Form.Label>Email Verified</Form.Label>
                    <p className="bold">{user.emailverified}</p>
                </Form.Group>
            </Col>
        </Row>
        {loading && <div className='text-center'>
            <i className='fa fa-spinner fa-pulse fa-2x text-danger ml-1 text-center' aria-hidden="true"></i>
        </div>}
        {!loading && <Row className="mt-2 border-bottom mb-4">
            <Col sm={3}>
                <Form.Group>
                    <Form.Label>2 FA Status</Form.Label>
                    <p className={`bold ${ twoFaStatus ? 'text-success' : 'text-secondary' }`}>
                        {(twoFaStatus ? 'Enabled' : 'Disabled')}
                        {!twoFaStatus && !generatedQR && <Button variant="primary" size='sm' className='ml-3' onClick={generate2FASecret} disabled={generatingQR}> Enable 2FA </Button>}
                        {twoFaStatus && !show2faDisablingForm && <Button size='sm' className='ml-3' onClick={onClick2FaDisable} > Disable 2 FA Code </Button>}
                        {generatingQR && <i className='fa fa-spinner fa-pulse text-danger ml-1' aria-hidden="true"></i>}
                    </p>
                </Form.Group>
            </Col>
            <Col sm={3}>
                <Form.Group>
                    <Form.Label>Last Updated</Form.Label>
                    <p className="bold">
                        {user.timestamp}
                    </p>
                </Form.Group>
            </Col>
        </Row>}
        {show2faEnablingForm && <Row>
            <Col>
                <Card>
                    <Card.Body>
                        <h4 className='text-primary text-center'>
                            Authentication through application
                        </h4>

                        {generatingQR && <div className='text-center mb-3'>
                            <i className='fa fa-spinner fa-pulse fa-2x' aria-hidden="true"></i>
                        </div>}
                        <div className="row mb-4">
                            <div className="col-sm-12 text-center">
                                <img src={qrCode} alt="" />
                            </div>
                        </div>
                        <p className='small'>
                            Scan this QR code using a mobile app ( for example, <span className='text-primary'> "Google authenticator" </span> or <span className='text-primary'>"Authy"</span>). The application will show a six-digit code, which must be specified in the next field.
                        </p>
                    </Card.Body>
                    <div className='card-footer'>
                        <Form id="2FAForm" className="mt-2" onSubmit={onEnabling2faHander} >
                            <Row>
                                <Col sm={{ span: 2, offset: 4 }}>
                                    <Form.Group>
                                        <input type="text" id="newSecretCode" value={newSecretCode} name="newSecretCode" className="text-center form-control" placeholder="Enter 6 digit code" required pattern="^[0-9]*$" maxlength="6" autocomplete="doNotAutoComplete" onChange={newSecretCodeHandler} />
                                    </Form.Group>
                                </Col>
                                <Col sm={2} className="text-center">
                                    <Button type="submit" variant='primary' disabled={enabling2FA} >
                                        Enable 2FA
                                        {enabling2FA && <i className='fa fa-spinner fa-pulse' aria-hidden="true" ></i>}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Card>
            </Col>

        </Row>}

        {show2faDisablingForm && <Row>
            <Col>
                <Card>
                    <Card.Body>
                        <h4 className='text-primary text-center'>
                            Disabling 2FA
                        </h4>
                        <p className='small'>
                            Enter six-digit code from your mobile app ( for example, <span className='text-primary'> "Google authenticator" </span> or <span className='text-primary'>"Authy"</span>). The application will show a six-digit code, which must be specified in the next field to disable 2FA.
                        </p>
                    </Card.Body>
                    <Card.Footer>
                        <Form id="2FAFormDisable" className="mt-2" onSubmit={disable2faHandler} >
                            <Row>
                                <Col sm={{ span: 2, offset: 4 }}>
                                    <Form.Group>
                                        <input type="text" id="newSecretCode" value={newSecretCode} className="form-control text-center" placeholder="Enter 6 digit code" required pattern="^[0-9]*$" maxlength="6" minlength="6" autocomplete="doNotAutoComplete" onChange={newSecretCodeHandler} />
                                    </Form.Group>
                                </Col>
                                <Col sm={2} className="text-center">
                                    <Button variant="primary" size="sm" disabled={disabling2FA} type="submit">
                                        Disable 2FA
                                        {disabling2FA && <i className='fa fa-spinner fa-pulse' aria-hidden="true" ></i>}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card.Footer>
                </Card>
            </Col >
        </Row >}
    </>
};

export default UserProfile;